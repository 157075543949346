import React, {useState} from "react";
import "./About.css";


const Info = () =>{

    return(
       <div className="about__info grid">
            <div className="about__box">
            <i class='bx bxs-school about__icon' ></i>
                <h3 className="about__title">Ausbildung</h3>
                <span className="about__subtitle">3 Jahre IMS</span>
            </div>

            <div className="about__box">
            <i class='bx bx-briefcase-alt about__icon' ></i>
                <h3 className="about__title">Job-Erfahrungen</h3>
                <span className="about__subtitle">3x Ferienjob</span>
            </div>

            <div className="about__box">
            <i class='bx bx-award about__icon'></i>
                <h3 className="about__title">Projekte</h3>
                <span className="about__subtitle">Siehe GitHub</span>
            </div>
       </div>
    )
}

export default Info