import React, {useState} from "react";
import "./Fotos.css";
import { Data } from "./Data";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";


const Fotos = () =>{

    return(
       <section className="fotos container section" id="fotos">
            <h2 className="section__title">Images</h2>
            <span className="section__subtitle">Carousel</span>

            <Swiper className="fotos__container"
            loop={true}
            grabCursor={true}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 48,
              },
            }}
            modules={[Pagination]}
            >
                {Data.map(({id, image}) => {
                    return(
                        <SwiperSlide className="fotos__card" key={id}>
                            <img src={image} alt="" className="fotos__img"></img>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
       </section>
    )
}

export default Fotos;