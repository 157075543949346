import React, {useState} from "react";
import "./Qualification.css"

const Qualification = () =>{

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return(
        <section className="qualification section" id="qualifications">
            <h2 className="section__title">Qualifications</h2>
            <span className="section__subtitle">Meine Laufbahn</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">

                    <div className={toggleState === 1 
                        ? "qualification__button qualification__active button--flex" 
                        : "qualification__button button--flex"
                    } onClick={() => toggleTab(1)}>
                   
                        <i className="uil uil-graduation-cap qualification__icon"></i> Ausbildung
                    </div>

                    <div className={toggleState === 2 
                        ? "qualification__button qualification__active button--flex" 
                        : "qualification__button button--flex"
                    } onClick={() => toggleTab(2)}>
                        
                        <i className="uil uil-briefcase-alt qualification__icon"></i> Erfahrung
                    </div>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 
                        ? "qualification__content qualification__content-active"
                        : "qualification__content "
                    }>
                        <i className="uil uil-arrow-up qualification__icon-left"></i>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Mittelschule</h3>
                                <span className="qualification__subtitle">IMS - Baden</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> 2020 - heute
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Oberstufe</h3>
                                <span className="qualification__subtitle">Bezirksschule - Baden</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> 2017 - 2020
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Primarschule</h3>
                                <span className="qualification__subtitle">Primarschule - Ehrendingen</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> 2011 - 2017
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>

                    <div className={toggleState === 2 
                        ? "qualification__content qualification__content-active"
                        : "qualification__content "
                    }>
                        <i className="uil uil-arrow-up qualification__icon-right"></i>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Ferienjob</h3>
                                <span className="qualification__subtitle">Amt für Informatik - Appenzell</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> Juli 2022 - 1 Woche
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Ferienjob</h3>
                                <span className="qualification__subtitle">Amt für Informatik - Appenzell</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> April 2022 - 2 Wochen
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Ferienjob</h3>
                                <span className="qualification__subtitle">Amt für Informatik - Appenzell</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calender-alt"></i> August 2021 - 3 Wochen
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qualification