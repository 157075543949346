import React, {useState} from "react";
import "./Footer.css";


const Footer = () =>{

    return(
       <footer className="footer">
        <div className="footer__container container">

            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>

                <li>
                    <a href="#skills" className="footer__link">Skills</a>
                </li>

                <li>
                    <a href="#qualifications" className="footer__link">Qualifications</a>
                </li>

                <li>
                    <a href="#fotos" className="footer__link">Images</a>
                </li>
            </ul>

            <div className="footer__social">
                <a href="https://www.instagram.com/_n.75k_/" className="footer__social-link" target="_blank">
                    <i class='bx bxl-instagram-alt' ></i>   
                </a>
                    
                <a href="https://www.linkedin.com/in/noel-keller-31293a23b/" className="footer__social-link" target="_blank">
                    <i class='bx bxl-linkedin' ></i>
                </a>
                    
                <a href="https://github.com/kellernoel" className="footer__social-link" target="_blank">
                    <i class='bx bxl-github' ></i>
                </a>
            </div>

            
        </div>
       </footer>
    )
}

export default Footer