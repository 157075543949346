import React, {useState} from "react";
import "./Home.css";

const Social = () =>{

    return(
        <div className="home__social">
            <a href="https://www.instagram.com/_n.75k_/" className="home__social-icon" target="_blank">
                <i class='bx bxl-instagram-alt' ></i> 
            </a>
            <a href="https://www.linkedin.com/in/noel-keller-31293a23b/" className="home__social-icon" target="_blank">
                <i class='bx bxl-linkedin' ></i>
            </a>
                
            <a href="https://github.com/kellernoel" className="home__social-icon" target="_blank">
                <i class='bx bxl-github' ></i>
            </a>
        </div>
    )
}

export default Social