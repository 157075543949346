import React, {useState} from "react";
import "./Skills.css";
import Frontend from "./Frontend";
import Backend from "./Backend";
import Datenverwaltung from "./Datenverwaltung";
import Projektmanagement from "./Projektmanagement";

const Skills = () =>{

    return(
        <section className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">Frontend/Backend/Datenverwaltung/Projektmanagement</span>

            <div className="skills__container container grid">
                <Frontend />
                <Backend />
                <Datenverwaltung />
                <Projektmanagement />
            </div>
        </section>
    )
}

export default Skills