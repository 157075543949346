import React, {useState} from "react";
import "./Skills.css";

const Projektmanagement = () =>{

    return(
       <div className="skills__content">
            <h3 className="skills__title">Projektmanagement</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i class='bx bx-badge-check'></i>
                        
                        <div>
                            <h3 className="skills__name">SCRUM</h3>
                            <span className="skills__level">Basics</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i class='bx bx-badge-check'></i>
                        
                        <div>
                            <h3 className="skills__name">IPERKA</h3>
                            <span className="skills__level">intermediate</span>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    )
}

export default Projektmanagement