import bild1 from "../../assets/bild1.jpeg";
import bild2 from "../../assets/bild2.jpeg";
import bild3 from "../../assets/bild3.jpeg";
import bild4 from "../../assets/bild4.jpeg";
import bild5 from "../../assets/bild5.jpeg";
import bild6 from "../../assets/bild6.jpeg";

export const Data = [
    {
      id: 1,
      image: bild1,
    },
    {
      id: 2,
      image: bild2,
    },
    {
      id: 3,
      image: bild3,
    },
    {
      id: 4,
      image: bild4,
    },
    {
      id: 5,
      image: bild5,
    },
    {
      id: 6,
      image: bild6,
    },
  ];